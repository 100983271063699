// import { merge } from 'theme-ui';
// //import  BaseTheme  from '../gatsby-theme-catalyst-core/components/theme';
// import { baseColors } from '@theme-ui/preset-tailwind';
// import { keyframes } from '@emotion/react';
import { merge } from 'theme-ui';
//const  BaseTheme  =  require('../gatsby-theme-catalyst-core/components/theme');
import { baseColors } from '@theme-ui/preset-tailwind';
import { keyframes } from '@emotion/react';
import { tailwind } from '@theme-ui/preset-tailwind';
const blink = keyframes({
	from: {
		textShadow: '0 -1px 0 #fff, 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, -4px 2px 0 #fff'
	},
	to: {
		textShadow: '0 -1px 0 #00f, 0 1px 0 #00f, 1px 0 0 #00f, -1px 0 0 #00f, -4px 2px 0 #00f'
	}
});

const blackShadow = {
	color: 'white',
	fontFamily: 'Oswald',
	textTransform: 'uppercase',
	textShadow: '0 -1px 0 #000, 0 1px 0 #000, 1px 0 0 #000, -1px 0 0 #000, -4px 2px 0 #000',
	letterSpacing: '0.06em'
};

const blueShadow = {
	textShadow: '0 -1px 0 #00f, 0 1px 0 #00f, 1px 0 0 #00f, -1px 0 0 #00f, -4px 2px 0 #00f'
};

const orangeShadow = {
	textShadow: '0 -1px 0 #ec6446, 0 1px 0 #ec6446, 1px 0 0 #ec6446, -1px 0 0 #ec6446, -3px 1px 0 #ec6446',
	color: 'white'
};

const grey = 'grey';
const greyShadow = {
	textShadow: `0 -1px 0 ${grey}, 0 1px 0 ${grey}, 1px 0 0 ${grey}, -1px 0 0 ${grey}, -3px 1px 0 ${grey}`,
	color: 'white'
};

const red = '#ff2fac';
const redShadow = {
	textShadow: `0 -1px 0 ${red}, 0 1px 0 ${red}, 1px 0 0 ${red}, -1px 0 0 ${red}, -3px 1px 0 ${red}`,
	color: 'white'
};

const blinkOrange = keyframes({
	from: {
		//	border: '1px dotted #9b9b9b',
		...greyShadow
	},
	to: {
		//	border: '1px dotted #ec6446',
		...orangeShadow
	}
});

const info = {
	fontSize: 2,
	fontFamily: 'Oswald',
	position: 'relative',
	...greyShadow
};

const theme = merge(tailwind, {
	fonts: {
		//body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
		heading: 'Oswald',
		body: 'Poppins'
		//monospace: 'Menlo, monospace'
	},
	text: {
		header: {
			fontSize: 5,
			textDecoration: 'none',
			display: 'inline-block',
			fontFamily: 'Oswald',
			color: 'white',
			//letterSpacing: '4rem',
			fontWeight: 600,
			...blackShadow,
			':hover': {
				animation: `.2s ${blink} infinite`,
				textDecoration: 'none'
			}
		},
		headerRed: {
			fontSize: 5,
			textDecoration: 'none',
			display: 'inline-block',
			fontFamily: 'Oswald',
			color: 'white',
			//letterSpacing: '4rem',
			fontWeight: 600,
			...redShadow
		},
		headerBlue: {
			fontSize: 5,
			textDecoration: 'none',
			display: 'inline-block',
			fontFamily: 'Oswald',
			color: 'white',
			//letterSpacing: '4rem',
			fontWeight: 600,
			...blueShadow
		},
		blink: {
			':hover': {
				animation: `.2s ${blink} infinite`,
				textDecoration: 'none'
			}
		},
		selected: {
			fontSize: 5,
			textDecoration: 'none',
			display: 'inline-block',
			fontFamily: 'Oswald',
			color: 'white',
			//letterSpacing: '4rem',
			fontWeight: 600,
			...blueShadow,
			':hover': {
				animation: `.2s ${blink} infinite`,
				textDecoration: 'none'
			},
			textTransform: 'uppercase'
		},
		dates: {
			fontSize: [ 3, null, '1.6rem' ],
			lineHeight: [ '120%' ],
			color: '#00f',
			margin: 0,
			fontFamily: 'Oswald',
			fontWeight: [ 500, null, 1000 ],
			mb: 2
		},
		note: {
			color: '#ff2fac'
		},
		more: {
			fontFamily: 'header',
			fontSize: 2,
			fontFamily: 'Oswald',
			letterSpacing: '0.15em',
			fontWeight: 400,
			textTransform: 'lowercase',
			textDecoration: 'none',
			...orangeShadow
		},
		info: {
			//variant: 'text.more',
			...info,
			':hover': {
				animation: `.2s ${blinkOrange} infinite`,
				textDecoration: 'none'
			}
		},
		infohover: {
			...info,
			animation: `.2s ${blinkOrange} infinite`,
			textDecoration: 'none'
		},
		headingBlog: {
			mt: 6,
			mb: 4,
			fontSize: [ 4, null, 6 ],
			textAlign: [ 'center', null, 'left' ],
			color: '#ff2fac',
			...redShadow
			// color: '#000',
			// fontSize: 3,
			// textShadow: 'none',
			// textTransform: 'none',
			// ...blackShadow
		}
		//name: { ...blackShadow }
	},
	colors: {
		primary: baseColors.blue[1],
		secondary: baseColors.red[5],
		accent: baseColors.gray[2],
		header: {
			background: 'transparent',
			backgroundOpen: 'white',
			text: baseColors.gray[8],
			textOpen: baseColors.gray[8],
			icons: baseColors.gray[6],
			iconsHover: baseColors.red[8],
			iconsOpen: baseColors.gray[6]
		},
		footer: {
			background: baseColors.gray[2],
			text: baseColors.gray[8],
			links: baseColors.gray[8],
			icons: baseColors.gray[8]
		}
	},
	styles: {
		root: {
			backgroundColor: 'transparent',
			color: 'text',
			fontFamily: 'body',
			fontWeight: 'body',
			lineHeight: 'body'
		},
		h1: {
			fontFamily: 'heading',
			fontWeight: 'heading',
			lineHeight: 'heading',
			m: 0,
			mb: 4,
			fontSize: 6,
			mt: 4
			// '::after': {
			// 	display: 'block',
			// 	content: '""',
			// 	width: [ '25%', '20%', '15%', null, '10%' ],
			// 	pt: 2,
			// 	borderBottomStyle: 'solid',
			// 	borderBottomWidth: '4px',
			// 	borderBottomColor: 'primary'
			// }
		},
		h2: {
			...blackShadow,
			fontFamily: 'heading',
			fontSize: 4
		},
		h3: {
			fontFamily: 'heading',
			fontStyle: 'italic',
			fontSize: 3
		},
		h4: {
			fontFamily: 'Poppins,sans-serif',
			fontSize: 1
		},
		p: {
			fontSize: [ 1, null, 2 ],
			my: 2
		},
		a: {
			color: '#00F',
			textDecoration: 'none',
			':visited': { color: 'inherit' },
			':hover': {
				color: '#ff6c3f',
				textDecoration: 'none'
			}
		}
	},
	forms: {
		input: {
			minHeight: '1em',
			width: [ '100%', null, '70%' ],
			//width: '100%',
			background: '#fff',
			border: '1px solid #00f',
			color: '#00f',
			padding: '1em 1em',
			fontSize: 3,
			margin: '1em 0',
			boxShadow: '4px 4px 0 #00f',
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
			borderRadius: 0,
			letterSpacing: '.3rem',
			':focus-visible': { outline: 'none' },
			'&::placeholder': {
				color: '#00f'
			}
		},
		textarea: {
			minHeight: '1em',
			//width: '100%',
			background: '#fff',
			border: '1px solid #00f',
			color: '#00f',
			padding: '1em 1em',
			fontSize: 3,
			margin: '1em 0',
			boxShadow: '4px 4px 0 #00f',
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
			borderRadius: 0,
			letterSpacing: '.3rem',
			':focus-visible': { outline: 'none' },
			'&::placeholder': {
				color: '#00f'
			}
		}
	},
	variant: {
		nav: {
			gridColumn: [ 'span 4', null, 'span 2' ]
		}
	},
	variants: {
		containerBoxShadow: {
			boxShadow: '8px 12px 0 #fff, 8px 12px 0 3px #00f'
		},
		contentContainer: {
			px: [ 3, null, 0 ],
			maxWidth: '1400px',
			width: '100vw',
			mx: 'auto',
			height: '100%'
		},

		siteTitle: {
			fontSize: [ 4, null, null, 5, null ],
			'::after': {
				display: 'none'
			}
		},
		// navUl: {
		// 	backgroundColor: 'white', mx:'auto'
		// },

		navLink: {
			postion: 'relative',
			color: '#fff',
			fontSize: 5,
			fontFamily: 'Oswald',
			//zIndex: 1020,
			//letterSpacing: '4rem',
			fontWeight: 600,
			textTransform: 'uppercase',
			pointerEvents: 'all',
			textShadow: '0 -1px 0 #000, 0 1px 0 #000, 1px 0 0 #000, -1px 0 0 #000, -4px 2px 0 #000',

			':hover': {
				animation: `.2s ${blink} infinite`,
				textDecoration: 'none'
			}
		},
		navLinkActive: {
			textDecoration: 'none',
			color: '#fff',
			textShadow: '0 -1px 0 #00f, 0 1px 0 #00f, 1px 0 0 #00f, -1px 0 0 #00f, -4px 2px 0 #00f'
		},
		navLinkSub: {
			'::after': {
				content: 'none'
			},
			':hover::after': {
				content: 'none'
			},
			':hover, :focus, :active': {
				color: 'primary'
			}
		},
		navLinkSubActive: {
			textDecoration: 'none',
			color: 'primary',
			'::after': {
				content: 'none'
			}
		},
		header: {
			maxWidth: '1600px',
			mx: 'auto'
		}
	},
	sizes: {
		maxPageWidth: '100vw', // Sets the max width of elements like the header/footer large screens
		maxContentWidth: '100vw'
		// Sets the container size on larger screens, e.g. tablets and laptops
	}
});

export default theme;
